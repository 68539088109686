import React, { useContext, useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';

import PostA from '../components/journal/detail/layouts/post-a';
import PostB from '../components/journal/detail/layouts/post-b';
import PostC from '../components/journal/detail/layouts/post-c';
import PostD from '../components/journal/detail/layouts/post-d';
import { AppContext, ThemeColor } from '../context/context';
import { Helmet } from 'react-helmet';

import '../css/journal_detail.css';

import RelatedItems from '../components/utils/related-items';
import PageMover, { PAGE_MOVER_MODE } from '../components/utils/page-mover';
import { PATH } from '../helper/config';

const JournalDetail: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);

  useEffect(() => {
    if (pageContext.locale === 'ja') {
      if (data.main.translated.length) {
        context.setLanguage(
          {
            ja: `${PATH.JOURNAL_DETAIL}${data.main.databaseId}`,
            en: `/en${PATH.JOURNAL_DETAIL}${data.main.translated[0].databaseId}`
          },
          pageContext.locale
        );
      } else {
        context.setLanguage(
          {
            ja: `${PATH.JOURNAL_DETAIL}${data.main.databaseId}`,
            en: `/en${PATH.JOURNAL}`
          },
          pageContext.locale
        );
      }
    } else {
      if (data.main.translated.length) {
        context.setLanguage(
          {
            ja: `${PATH.JOURNAL_DETAIL}${data.main.translated[0].databaseId}`,
            en: `/en${PATH.JOURNAL_DETAIL}${data.main.databaseId}`
          },
          pageContext.locale
        );
      } else {
        context.setLanguage(
          {
            ja: `${PATH.JOURNAL}`,
            en: `/en${PATH.JOURNAL_DETAIL}${data.main.databaseId}`
          },
          pageContext.locale
        );
      }
    }
  }, []);

  const createNavigation = (): any => {
    let detectID: number = 0;
    for (let i: number = 0; i < data.navigation.edges.length; i++) {
      if (data.navigation.edges[i].node.databaseId === pageContext.databaseId) {
        detectID = i;
      }
    }
    let prevID: number = detectID - 1;
    let nextID: number = detectID + 1;
    if (prevID < 0) prevID = data.navigation.edges.length - 1;
    if (nextID >= data.navigation.edges.length) nextID = 0;

    return {
      prev: { ...data.navigation.edges[prevID].node },
      next: { ...data.navigation.edges[nextID].node }
    };
  };
  const navigation: any = useMemo(() => createNavigation(), []);

  useEffect(() => {
    context.setTheme(ThemeColor.DEFAULT);
    return () => {
      context.setTheme(ThemeColor.DEFAULT);
    };
  }, []);

  if (typeof window !== `undefined`) {
    useEffect(() => {
      if (data.main.gqlJournalCategories && data.main.gqlJournalCategories.nodes && data.main.gqlJournalCategories.nodes.length) {
        switch (data.main.gqlJournalCategories.nodes[0].name) {
          case 'ANOTHER STORY':
            context.setTheme(ThemeColor.BLACK);
            break;
          case 'ARTIST TALK':
            context.setTheme(ThemeColor.DEFAULT);
            break;
          case 'LIFE STYLE':
            context.setTheme(ThemeColor.BLACK);
            break;
          case 'SHORT COLUMN':
            context.setTheme(ThemeColor.WHITE);
            break;
          case 'POST-FAKE PRODUCT':
            context.setTheme(ThemeColor.BROWN);
            break;
          case 'THE REAL REVIEW':
            context.setTheme(ThemeColor.DARK_GRAY);
            break;
        }
      }
    }, [data]);
  }

  useEffect(() => {
    let metaTag;
    metaTag = document.querySelector(`meta[name="Description"]`);
    if (metaTag) metaTag.remove();

    metaTag = document.querySelector(`meta[name="og:description"]`);
    if (metaTag) metaTag.remove();

    metaTag = document.querySelector(`meta[name="twitter:description"]`);
    if (metaTag) metaTag.remove();
  }, []);

  return (
    <section className="journal-detail">
      <Helmet>
        <title>{data.main.title} | POST-FAKE</title>
        <meta name="Description" content="" />
        <meta property="og:description" content="" />
        <meta property="og:title" content={`${data.main.title} | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.JOURNAL_DETAIL}${data.main.databaseId}`} />
        {data.main &&
          data.main.optionJournalFields &&
          data.main.optionJournalFields.image &&
          data.main.optionJournalFields.image.src &&
          data.main.optionJournalFields.image.src.mediaItemUrl && (
            <meta property="og:image" content={data.main.optionJournalFields.image.src.mediaItemUrl} />
          )}
        <meta name="twitter:site" content={`${data.main.title} | POST-FAKE`} />
        <meta name="twitter:title" content={`${data.main.title} | POST-FAKE`} />
        <meta name="twitter:description" content="" />
      </Helmet>
      {((): any => {
        if (data.main.gqlJournalCategories && data.main.gqlJournalCategories.nodes && data.main.gqlJournalCategories.nodes.length) {
          switch (data.main.gqlJournalCategories.nodes[0].name) {
            case 'ANOTHER STORY':
              return <PostD data={data.main} locale={pageContext.locale} />;
            case 'ARTIST TALK':
              return <PostA data={data.main} locale={pageContext.locale} />;
            case 'LIFE STYLE':
              return <PostC data={data.main} locale={pageContext.locale} />;
            case 'SHORT COLUMN':
              return <PostB data={data.main} locale={pageContext.locale} />;
            case 'POST-FAKE PRODUCT':
              return <PostB data={data.main} locale={pageContext.locale} />;
            case 'THE REAL REVIEW':
              return <PostB data={data.main} locale={pageContext.locale} />;
            default:
              return <PostA data={data.main} locale={pageContext.locale} />;
          }
        } else {
          return <PostA data={data.main} locale={pageContext.locale} />;
        }
      })()}

      {data.main.gqlRelatedProductsAcf.products && (
        <RelatedItems data={data.main.gqlRelatedProductsAcf.products} locale={pageContext.locale} />
      )}
      <PageMover navigation={navigation} mode={PAGE_MOVER_MODE.JOURNAL} locale={pageContext.locale} />
    </section>
  );
};
export default JournalDetail;

export const query = graphql`
  query journalDetailQuery($databaseId: Int!, $locale: String!) {
    main: wpGqlJournalItem(databaseId: { eq: $databaseId }) {
      databaseId
      title
      date
      gqlJournalCategories {
        nodes {
          name
        }
      }
      gqlRelatedProductsAcf {
        products {
          name
          price
          title
          url
          image {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
        }
      }
      optionJournalFields {
        title
        credit
        volumes {
          text
          url
        }
        image {
          src {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
          caption
        }
        contents {
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockHeading2 {
            fieldGroupName
            text
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockHeading3 {
            fieldGroupName
            text
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockTextColumn {
            columns
            fieldGroupName
            text
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockQuote {
            fieldGroupName
            text
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockImage1column {
            caption1
            fieldGroupName
            image1 {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockImage2column {
            caption1
            caption2
            fieldGroupName
            image1 {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
            image2 {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockYouttubeid {
            fieldGroupName
            youttubeid
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockSlides {
            fieldGroupName
            images {
              image {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockProfile {
            fieldGroupName
            name
            position
            profile
          }
          ... on WpGqlJournalItem_Optionjournalfields_Contents_BlockLead {
            fieldGroupName
            text
            title
          }
        }
      }
      translated {
        databaseId
      }
    }

    navigation: allWpGqlJournalItem(filter: { locale: { locale: { eq: $locale } } }) {
      edges {
        node {
          databaseId
          gqlJournalCategories {
            nodes {
              name
            }
          }
          optionJournalFields {
            title
          }
        }
      }
    }
  }
`;
