import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { AppContext } from '../../context/context';
import { PATH } from '../../helper/config';

const BreadCrumb: React.FunctionComponent<any> = ({ title }) => {
  const context = useContext(AppContext);
  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  return (
    <div className="util__breadcrumb">
      <ul className="util__breadcrumb-list">
        <li className="util__breadcrumb-list-item">
          <Link to={`${localePath}${PATH.ROOT}`} className="link__text">
            HOME
          </Link>
        </li>
        <li className="util__breadcrumb-list-item">
          <Link to={`${localePath}${PATH.JOURNAL}`} className="link__text">
            JOURNAL
          </Link>
        </li>
        <li className="util__breadcrumb-list-item">
          <span>{title}</span>
        </li>
      </ul>
    </div>
  );
};
export default BreadCrumb;
