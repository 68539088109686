import React from 'react';

const ChapterText: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__chapter-text">
      {data.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockHeading2' && (
        <h2
          className="journal-detail__chapter-text-catch"
          dangerouslySetInnerHTML={{ __html: data.text ? data.text.split('\r\n').join('<br>') : '' }}></h2>
      )}
      {data.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockHeading3' && (
        <h3
          className="journal-detail__chapter-text-sub-catch"
          dangerouslySetInnerHTML={{ __html: data.text ? data.text.split('\r\n').join('<br>') : '' }}></h3>
      )}
    </div>
  );
};
export default ChapterText;
