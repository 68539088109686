import React from 'react';
import Sticky from 'react-sticky-el';
import { OnlyPC, OnlySP } from '../../../../helper/media-helper';
import Util from '../../../../templates/utils/util';

const SideBar: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__a-sidebar">
      <Sticky bottomOffset={0} boundaryElement=".journal-detail__a-sidebar">
        <div className="journal-detail__a-sidebar-content">
          <div className="journal-detail__a-sidebar-content-inner">
            <OnlyPC>
              <div className="journal-detail__a-sidebar-content-row">
                <p className="journal-detail__a-sidebar-content-date ff-mono">{Util.GET_DATE(data.date).ja}</p>
                <div className="journal-detail__a-sidebar-content-column">
                  {data.optionJournalFields.title && (
                    <p
                      className="journal-detail__a-sidebar-content-title"
                      dangerouslySetInnerHTML={{ __html: data.optionJournalFields.title.split('\r\n').join('<br>') }}></p>
                  )}
                  {data.optionJournalFields.credit && (
                    <p
                      className="journal-detail__a-sidebar-content-author ff-mono"
                      dangerouslySetInnerHTML={{ __html: data.optionJournalFields.credit.split('\r\n').join('<br>') }}></p>
                  )}
                </div>
              </div>
            </OnlyPC>
            <OnlySP>
              <p className="journal-detail__a-sidebar-content-date ff-mono">{Util.GET_DATE(data.date).ja}</p>
              {data.optionJournalFields.title && (
                <p
                  className="journal-detail__a-sidebar-content-title"
                  dangerouslySetInnerHTML={{ __html: data.optionJournalFields.title.split('\r\n').join('<br>') }}></p>
              )}
            </OnlySP>
          </div>
        </div>
      </Sticky>
    </div>
  );
};

export default SideBar;
