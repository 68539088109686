import React, { useEffect, useState } from 'react';

const SNS: React.FunctionComponent = () => {
  const [url, setURL] = useState('');

  if (typeof window !== 'undefined') {
    useEffect(() => {
      setURL(window.location.href);
    }, []);
  }

  return (
    <div className="util__sns-navigation">
      <ul className="util__sns-navigation-list ff-univ">
        <li className="util__sns-navigation-list-item">
          <a href={`https://twitter.com/intent/tweet?url=${url}`} className="link__alpha" target="_blank" rel="noopener">
            <i className="glyphs-twitter"></i>
          </a>
        </li>
        <li className="util__sns-navigation-list-item">
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} className="link__alpha" target="_blank" rel="noopener">
            <i className="glyphs-facebook"></i>
          </a>
        </li>
        <li className="util__sns-navigation-list-item">
          <a href={`https://timeline.line.me/social-plugin/share?url=${url}`} className="link__alpha" target="_blank" rel="noopener">
            <i className="glyphs-line"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};
export default SNS;
