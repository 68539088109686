import React from 'react';
import Util from '../../../../templates/utils/util';

const PostTitle: React.FunctionComponent<any> = (props) => {
  return (
    <div className="journal-detail__post-title ff-univ">
      <p className="journal-detail__post-title-date ff-mono">{Util.GET_DATE(props.data.date).ja}</p>
      {props.data.optionJournalFields.title && (
        <h1
          className="journal-detail__post-title-caption"
          dangerouslySetInnerHTML={{ __html: props.data.optionJournalFields.title.split('\r\n').join('<br>') }}></h1>
      )}
      {props.data.gqlJournalCategories && props.data.gqlJournalCategories.nodes && props.data.gqlJournalCategories.nodes.length && (
        <p className="journal-detail__post-title-category ff-mono">#{props.data.gqlJournalCategories.nodes[0].name}</p>
      )}
      {props.hasCaption && props.data.optionJournalFields.credit && (
        <div
          className="journal-detail__post-title-author ff-mono"
          dangerouslySetInnerHTML={{ __html: props.data.optionJournalFields.credit.split('\r\n').join('<br>') }}></div>
      )}
    </div>
  );
};
export default PostTitle;
