import React from 'react';
import { OnlyBreakPC, OnlySP } from '../../../../helper/media-helper';

import Sticky from 'react-sticky-el';
import SplashImage from '../units/splash-image';
import SerializationNavi from '../units/serialization-navi';
import PostTitle from '../units/post-title';
import Author from '../units/author';

import PostContent from '../modules/post-content';
import BreadCrumb from '../../../utils/bread-crumb';
import SNS from '../../../utils/sns';

const PostB: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__b">
      <div className="journal-detail__b-row">
        <OnlyBreakPC>
          <div className="journal-detail__b-side-column">
            <Sticky bottomOffset={0} boundaryElement=".journal-detail__b-side-column">
              <div className="journal-detail__b-side-column-el">
                <div className="journal-detail__b-side-column-el-inner">
                  {data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} />}
                  <PostTitle data={data} hasCaption={true} />
                </div>
              </div>
            </Sticky>
          </div>
        </OnlyBreakPC>
        <div className="journal-detail__b-column util__page">
          <Content data={data} />
        </div>
        <BreadCrumb title={data.title} />
        <SNS />
      </div>
    </div>
  );
};
export default PostB;

/* =================================================================== */
/* =================================================================== */
/* =================================================================== */
const Content: React.FunctionComponent<any> = ({ data }) => {
  let authorCount: number = -1;
  return (
    <div className="journal-detail__b-content">
      <SplashImage data={data} />
      <OnlySP>
        {data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} prefix="sp" />}
        <PostTitle data={data} hasCaption={true} />
      </OnlySP>

      <PostContent data={data.optionJournalFields.contents} />

      <OnlySP>{data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} prefix="sp" />}</OnlySP>
      {data.optionJournalFields.contents &&
        data.optionJournalFields.contents.map((elem: any, i: number) => {
          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockProfile') {
            authorCount++;
            return <Author key={`journal-detail-author-${i}`} data={elem} count={authorCount} />;
          }
        })}
    </div>
  );
};
