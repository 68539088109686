import React from 'react';

const MultiColumnText: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div
      className={`journal-detail__multi-column-text journal-detail__multi-column-text--${data.columns}`}
      dangerouslySetInnerHTML={{ __html: data.text }}></div>
  );
};
export default MultiColumnText;
