import React from 'react';
import { OnlyPC, OnlySP } from '../../../../helper/media-helper';

import SplashFilmImage from '../units/splash-film-image';
import SerializationNavi from '../units/serialization-navi';
import PostTitle from '../units/post-title';
import Author from '../units/author';

import PostContent from '../modules/post-content';
import BreadCrumb from '../../../utils/bread-crumb';
import SNS from '../../../utils/sns';

const PostD: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__d util__page">
      {data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} />}
      <PostTitle data={data} />

      <div className="journal-detail__d-row">
        <div className="journal-detail__d-left-column">
          <SplashFilmImage data={data} />
          <PostContent data={data.optionJournalFields.contents} type="D-Left" />
        </div>
        <div className="journal-detail__d-column">
          <Content data={data} />
        </div>
      </div>
    </div>
  );
};
export default PostD;

/* =================================================================== */
/* =================================================================== */
/* =================================================================== */
const Content: React.FunctionComponent<any> = ({ data }) => {
  let authorCount: number = -1;
  return (
    <div className="journal-detail__d-content">
      <PostContent data={data.optionJournalFields.contents} type="D-Right" />

      <OnlySP>{data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} prefix="sp" />}</OnlySP>

      {data.optionJournalFields.contents &&
        data.optionJournalFields.contents.map((elem: any, i: number) => {
          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockProfile') {
            authorCount++;
            return <Author key={`journal-detail-author-${i}`} data={elem} count={authorCount} />;
          }
        })}
      <BreadCrumb title={data.title} />
      <SNS />
    </div>
  );
};
