import React from 'react';
import { OnlyPC, OnlySP } from '../../../../helper/media-helper';

import SplashColumnImage from '../units/splash-column-image';
import SerializationNavi from '../units/serialization-navi';
import PostTitle from '../units/post-title';
import Author from '../units/author';

import PostContent from '../modules/post-content';
import BreadCrumb from '../../../utils/bread-crumb';
import SNS from '../../../utils/sns';

const PostC: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__c">
      <div className="journal-detail__c-row">
        <Content data={data} />
      </div>
    </div>
  );
};
export default PostC;

/* =================================================================== */
/* =================================================================== */
/* =================================================================== */
const Content: React.FunctionComponent<any> = ({ data }) => {
  let authorCount: number = -1;
  return (
    <div className="journal-detail__c-content util__page">
      <div className="journal-detail__c-content-inner journal-detail__c-content-inner--primary">
        {data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} />}
        <PostTitle data={data} />
      </div>
      <SplashColumnImage data={data} />
      <div className="journal-detail__c-content-inner">
        <PostContent data={data.optionJournalFields.contents} />

        <OnlySP>{data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} />}</OnlySP>
        {data.optionJournalFields.contents &&
          data.optionJournalFields.contents.map((elem: any, i: number) => {
            if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockProfile') {
              authorCount++;
              return <Author key={`journal-detail-author-${i}`} data={elem} count={authorCount} />;
            }
          })}
      </div>

      <BreadCrumb title={data.title} />
      <SNS />
    </div>
  );
};
