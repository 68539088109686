import { Link } from 'gatsby';
import React from 'react';

// 連載記事ナビゲーション
const SerializationNavi: React.FunctionComponent<any> = ({ data, prefix }) => {
  return (
    <nav className="journal-detail__serialization-navi ff-mono">
      <ul className="journal-detail__serialization-navi-list">
        {data &&
          data.map((elem: any, i: number) => {
            let isActive: boolean = false;
            if (typeof window !== 'undefined') {
              isActive = window.location.href.includes(elem.url);
            }
            if (elem.url) {
              return (
                <li
                  className="journal-detail__serialization-navi-list-item"
                  key={`journal-detail__serialization-navi-list-item-${i}-${prefix}`}>
                  {((): any => {
                    if (elem.url.includes('http')) {
                      return (
                        <a href={elem.url} className={`link__text ${isActive ? 'active' : ''}`}>
                          vol.{elem.text}
                        </a>
                      );
                    } else {
                      return (
                        <Link to={elem.url} className={`link__text ${isActive ? 'active' : ''}`}>
                          vol.{elem.text}
                        </Link>
                      );
                    }
                  })()}
                </li>
              );
            }
          })}
      </ul>
    </nav>
  );
};
export default SerializationNavi;
