import React from 'react';
import LazyImage from '../../../utils/lazy-image';

const SinglePhoto: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__splash-image">
      <figure className="journal-detail__splash-image-figure">
        <span className="journal-detail__splash-image-wrap">
          {data.optionJournalFields.image && <LazyImage data={data.optionJournalFields.image.src} />}
        </span>
      </figure>

      <p className="journal-detail__splash-image-author ff-mono">{data.optionJournalFields.image.caption}</p>
    </div>
  );
};
export default SinglePhoto;
