import React from 'react';
import LazyImage from '../../../utils/lazy-image';

const SinglePhoto: React.FunctionComponent<any> = ({ data }) => {
  return (
    <figure className="journal-detail__single-photo">
      <span className="journal-detail__single-photo-wrap">{data.image1 && <LazyImage data={data.image1} />}</span>
      <figcaption className="journal-detail__single-photo-caption">{data.caption1}</figcaption>
    </figure>
  );
};
export default SinglePhoto;
