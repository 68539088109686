import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import LazyImage from '../../../utils/lazy-image';
SwiperCore.use([Autoplay, Navigation, Pagination]);

const SlideImage: React.FunctionComponent<any> = ({ data, count }) => {
  return (
    <div className="journal-detail__slide-image">
      <div className="journal-detail__slide-image-wrap">
        <Swiper
          navigation={{
            nextEl: `.journal-detail__slide-image-next-${count}`,
            prevEl: `.journal-detail__slide-image-prev-${count}`
          }}
          pagination={{
            el: `.journal-detail__slide-image-navigation-count-${count}`,
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + ' link__alpha">' + (index + 1) + '</span>';
            }
          }}>
          {data.images &&
            data.images.map((elem: any, i: number) => {
              return (
                <SwiperSlide key={`journal-detail__slide-image-${count}-${i}`}>
                  {elem.image && <LazyImage className="fit fit--contain" data={elem.image} />}
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
      <nav className="journal-detail__slide-image-navigation">
        <div className="journal-detail__slide-image-navigation-row">
          <p
            className={`journal-detail__slide-image-prev journal-detail__slide-image-prev-${count} ff-univ-bold swiper-button-prev link__alpha`}>
            Prev
          </p>
          <p
            className={`journal-detail__slide-image-next journal-detail__slide-image-next-${count} ff-univ-bold swiper-button-next link__alpha`}>
            Next
          </p>
        </div>
        <div
          className={`journal-detail__slide-image-navigation-count journal-detail__slide-image-navigation-count-${count} swiper-pagination ff-mono`}></div>
      </nav>
    </div>
  );
};
export default SlideImage;
