import React from 'react';
import Util from '../../../../templates/utils/util';
import LazyImage from '../../../utils/lazy-image';

const SplashFilmImage: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__splash-film-image">
      <div className="journal-detail__splash-film-image-caption-container">
        {data.optionJournalFields.credit && (
          <p
            className="journal-detail__splash-film-image-caption ff-mono"
            dangerouslySetInnerHTML={{ __html: data.optionJournalFields.credit.split('\r\n').join('<br>') }}></p>
        )}
      </div>
      <div className="journal-detail__splash-film-image-image">
        {data.optionJournalFields.image && <LazyImage data={data.optionJournalFields.image.src} />}
      </div>
      <div className="journal-detail__splash-film-image-date-container">
        <p className="journal-detail__splash-film-image-date ff-mono">{Util.GET_DATE(data.date).ja}</p>
      </div>
    </div>
  );
};
export default SplashFilmImage;
