import React from 'react';
import SplashFilmImage from '../units/splash-film-image';

const SplashColumnImage: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__splash-column-image">
      <div className="journal-detail__splash-column-image-row">
        <div className="journal-detail__splash-column-image-content">
          <SplashFilmImage data={data} />
        </div>

        <div className="journal-detail__splash-column-image-column">
          {data.optionJournalFields.contents &&
            data.optionJournalFields.contents.map((elem: any, i: number) => {
              if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockLead') {
                return (
                  <React.Fragment key={`GqlJournalItem_Optionjournalfields_Contents_BlockLead_${i}`}>
                    {elem.title && (
                      <div className="journal-detail__chapter-text">
                        <h3
                          className="journal-detail__chapter-text-catch"
                          dangerouslySetInnerHTML={{ __html: elem.title.split('\r\n').join('<br>') }}></h3>
                      </div>
                    )}
                    <div dangerouslySetInnerHTML={{ __html: elem.text }} />
                  </React.Fragment>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};
export default SplashColumnImage;
