import React from 'react';
import { OnlyPC, OnlySP } from '../../../../helper/media-helper';

import SideBar from '../modules/side-bar';
import SplashImage from '../units/splash-image';
import SerializationNavi from '../units/serialization-navi';
import PostTitle from '../units/post-title';

import PostContent from '../modules/post-content';
import Author from '../units/author';

import BreadCrumb from '../../../utils/bread-crumb';
import SNS from '../../../utils/sns';

const PostA: React.FunctionComponent<any> = ({ data }) => {
  return (
    <div className="journal-detail__a">
      <div className="journal-detail__a-row">
        <SideBar data={data} />
        <div className="journal-detail__a-column util__page">
          <Content data={data} />
        </div>
      </div>
    </div>
  );
};
export default PostA;

/* =================================================================== */
/* =================================================================== */
/* =================================================================== */
const Content: React.FunctionComponent<any> = ({ data }) => {
  let authorCount: number = -1;
  return (
    <div className="journal-detail__a-content">
      <SplashImage data={data} />
      <OnlySP>
        {data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} prefix="sp" />}
        <PostTitle data={data} hasCaption={true} />
      </OnlySP>
      <OnlyPC>
        {data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} />}
        <PostTitle data={data} />
      </OnlyPC>
      <PostContent data={data.optionJournalFields.contents} />

      <OnlySP>{data.optionJournalFields.volumes && <SerializationNavi data={data.optionJournalFields.volumes} prefix="sp" />}</OnlySP>
      {data.optionJournalFields.contents &&
        data.optionJournalFields.contents.map((elem: any, i: number) => {
          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockProfile') {
            authorCount++;
            return <Author key={`journal-detail-author-${i}`} data={elem} count={authorCount} />;
          }
        })}

      <BreadCrumb title={data.title} />
      <SNS />
    </div>
  );
};
