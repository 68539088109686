import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/context';
import { CATEGORIES, PATH } from '../../helper/config';

interface IPageMoverPropsElem {
  label: string;
  category: string;
}

interface IPageMoverProps {
  prev?: IPageMoverPropsElem;
  next?: IPageMoverPropsElem;
  navigation?: any;
  mode?: number;
  locale?: string;
}

export enum PAGE_MOVER_MODE {
  JOURNAL,
  ARTIST,
  DICTIONARY
}

const PageMover: React.FunctionComponent<IPageMoverProps> = (props) => {
  const context = useContext(AppContext);
  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  if (typeof props.mode === 'undefined') {
    return <nav className="util__page-mover"></nav>;
  }

  if (props.mode === PAGE_MOVER_MODE.ARTIST) {
    return (
      <nav className="util__page-mover">
        <div className="util__page-mover-row">
          <Link to={`${localePath}${PATH.ARTISTS_DETAIL}${props.navigation.prev.databaseId}`} className="util__page-mover-btn">
            <p className="util__page-mover-caption ff-univ-bold">Prev</p>
            <div className="util__page-mover-btn-column">
              <p className="util__page-mover-btn-title ff-sans-bold">{props.navigation.prev.artist.nameEnglish}</p>
              <p className="util__page-mover-btn-category ff-mono">{props.navigation.prev.artist.position}</p>
            </div>
          </Link>
          <Link to={`${localePath}${PATH.ARTISTS_DETAIL}${props.navigation.next.databaseId}`} className="util__page-mover-btn">
            <p className="util__page-mover-caption ff-univ-bold">Next</p>
            <div className="util__page-mover-btn-column">
              <p className="util__page-mover-btn-title  ff-sans-bold">{props.navigation.next.artist.nameEnglish}</p>
              <p className="util__page-mover-btn-category ff-mono">{props.navigation.next.artist.position}</p>
            </div>
          </Link>
        </div>
        <div className="util__page-mover-all">
          <Link to={`${localePath}${PATH.ARTISTS}`} className="link__text ff-univ-bold">
            ● BACK TO ALL
          </Link>
        </div>
      </nav>
    );
  }

  if (props.mode === PAGE_MOVER_MODE.DICTIONARY) {
    return (
      <nav className="util__page-mover">
        <div className="util__page-mover-row">
          <Link to={`${localePath}${PATH.DICTIONARY_DETAIL}${props.navigation.prev.databaseId}`} className="util__page-mover-btn">
            <p className="util__page-mover-caption ff-univ-bold">Prev</p>
            <div className="util__page-mover-btn-column">
              <p className="util__page-mover-btn-title ff-sans-bold">{props.navigation.prev.gqlDictionariesAcf.title}</p>
              <p className="util__page-mover-btn-category ff-mono">#POST-FAKE DICTIONARY</p>
            </div>
          </Link>
          <Link to={`${localePath}${PATH.DICTIONARY_DETAIL}${props.navigation.next.databaseId}`} className="util__page-mover-btn">
            <p className="util__page-mover-caption ff-univ-bold">Next</p>
            <div className="util__page-mover-btn-column">
              <p className="util__page-mover-btn-title  ff-sans-bold">{props.navigation.next.gqlDictionariesAcf.title}</p>
              <p className="util__page-mover-btn-category ff-mono">#POST-FAKE DICTIONARY</p>
            </div>
          </Link>
        </div>
        <div className="util__page-mover-all">
          <Link to={`${localePath}${PATH.DICTIONARY}`} className="link__text ff-univ-bold">
            ● BACK TO ALL
          </Link>
        </div>
      </nav>
    );
  }
  if (props.mode === PAGE_MOVER_MODE.JOURNAL) {
    return (
      <nav className="util__page-mover">
        <div className="util__page-mover-row">
          <Link to={`${localePath}${PATH.JOURNAL_DETAIL}${props.navigation.prev.databaseId}`} className="util__page-mover-btn">
            <p className="util__page-mover-caption ff-univ-bold">Prev</p>
            <div className="util__page-mover-btn-column">
              <p className="util__page-mover-btn-title ff-sans-bold">{props.navigation.prev.optionJournalFields.title}</p>
              {props.navigation.prev.gqlJournalCategories &&
                props.navigation.prev.gqlJournalCategories.nodes &&
                props.navigation.prev.gqlJournalCategories.nodes.length && (
                  <p className="util__page-mover-btn-category ff-mono">#{props.navigation.prev.gqlJournalCategories.nodes[0].name}</p>
                )}
            </div>
          </Link>
          <Link to={`${localePath}${PATH.JOURNAL_DETAIL}${props.navigation.next.databaseId}`} className="util__page-mover-btn">
            <p className="util__page-mover-caption ff-univ-bold">Next</p>
            <div className="util__page-mover-btn-column">
              <p className="util__page-mover-btn-title  ff-sans-bold">{props.navigation.next.optionJournalFields.title}</p>
              {props.navigation.next.gqlJournalCategories &&
                props.navigation.next.gqlJournalCategories.nodes &&
                props.navigation.next.gqlJournalCategories.nodes.length && (
                  <p className="util__page-mover-btn-category ff-mono">#{props.navigation.next.gqlJournalCategories.nodes[0].name}</p>
                )}
            </div>
          </Link>
        </div>
        <div className="util__page-mover-all">
          <Link to={`${localePath}${PATH.JOURNAL}`} className="link__text ff-univ-bold">
            ● BACK TO ALL
          </Link>
        </div>
      </nav>
    );
  }
};
export default PageMover;
