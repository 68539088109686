import React from 'react';
import YouTube from 'react-youtube';

const Movie: React.FunctionComponent<any> = ({ data }) => {
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
      controls: 1,
      loop: 0,
      rel: 0,
      playsinline: 1
      // mute: 1
    }
  };
  return (
    <div className="journal-detail__movie">
      <YouTube videoId={data.youttubeid} opts={opts} />
    </div>
  );
};
export default Movie;
