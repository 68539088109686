import React from 'react';

const Author: React.FunctionComponent<any> = ({ data, count }) => {
  return (
    <div className={`journal-detail__author journal-detail__author--${count}`}>
      {count === 0 && <h3 className="journal-detail__author-caption ff-univ-bold">PROFILE</h3>}
      <div className="journal-detail__author-column">
        <p className="journal-detail__author-name">{data.name}</p>
        <p className="journal-detail__author-role ff-mono">{data.position}</p>
        <div className="journal-detail__author-description" dangerouslySetInnerHTML={{ __html: data.profile }}></div>
      </div>
    </div>
  );
};
export default Author;
