import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import LazyImage from '../../../utils/lazy-image';

export enum DoublePhotoAlign {
  TOP,
  CENTER,
  BOTTOM,
  SLIP
}

const DoublePhoto: React.FunctionComponent<any> = ({ data }) => {
  let alignClass: string = 'top';

  return (
    <div className={`journal-detail__double-photo ${alignClass}`}>
      <figure className="journal-detail__double-photo-figure">
        <span className="journal-detail__double-photo-wrap">{data.image1 && <LazyImage data={data.image1} />}</span>
        <figcaption className="journal-detail__double-photo-caption">{data.caption1}</figcaption>
      </figure>
      <figure className="journal-detail__double-photo-figure">
        <span className="journal-detail__double-photo-wrap">{data.image2 && <LazyImage data={data.image2} />}</span>
        <figcaption className="journal-detail__double-photo-caption">{data.caption2}</figcaption>
      </figure>
    </div>
  );
};
export default DoublePhoto;
