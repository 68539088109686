import React from 'react';
import ChapterText from '../units/chapter-text';
import MultiColumnText from '../units/multi-column-text';
import SinglePhoto from '../units/single-photo';
import DoublePhoto from '../units/double-image';
import SlideImage from '../units/slide-image';
import Movie from '../units/movie';
import QuoteText from '../units/quote_text';

const PostContent: React.FunctionComponent<any> = ({ data, type }) => {
  return (
    <>
      {data &&
        data.length &&
        data.map((elem: any, i: number) => {
          const key: string = `journal-detail-content-${i}`;
          if (
            (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockHeading2' && type !== 'D-Left') ||
            (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockHeading3' && type !== 'D-Left')
          ) {
            return <ChapterText key={key} data={elem} />;
          }

          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockTextColumn' && type !== 'D-Left') {
            return <MultiColumnText key={key} data={elem} />;
          }

          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockImage1column' && type !== 'D-Right') {
            return <SinglePhoto key={key} data={elem} />;
          }

          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockImage2column' && type !== 'D-Right') {
            return <DoublePhoto key={key} data={elem} />;
          }

          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockSlides' && type !== 'D-Right') {
            return <SlideImage key={key} data={elem} count={i} />;
          }

          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockYouttubeid' && type !== 'D-Right') {
            return <Movie key={key} data={elem} />;
          }

          if (elem.fieldGroupName === 'GqlJournalItem_Optionjournalfields_Contents_BlockQuote' && type !== 'D-Left') {
            return <QuoteText key={key} data={elem} />;
          }
        })}
    </>
  );
};
export default PostContent;
